import React from "react";
import Layout from "../components/layout/Layout";
import { graphql } from "gatsby";
import MapComponents from "../constants/mapping";
import loadable from "@loadable/component";
import SEO from "../components/seo";
import { Spinner } from "reactstrap";

const HomePage = ({ data, location }) => {
  const { modules } = data.contentfulPageHome;

  const components = modules.map((component) =>
    loadable(() => import(`../${MapComponents[component.__typename]}`), {
      fallback: (
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            color="success"
            style={{ width: "10rem", height: "10rem" }}
          />
        </div>
      ),
    })
  );

  return (
    <Layout>
      <SEO title="Home" />
      {components.map((Component, i) => (
        <Component {...modules[i]} />
      ))}
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulPageHome {
      modules {
        ... on ContentfulComponentHero {
          id
          buttonText
          buttonUrl
          headline
          image {
            file {
              url
            }
          }
        }
        ... on ContentfulModuleCaption {
          id
          word1
          word2
          word3
          word4
        }
        ... on ContentfulModuleInformativeAdditionalAddress {
          id
          uid
          header
          hours {
            day
            close
            open
          }
          phone
          tagline
          address
          components {
            header
            image {
              fluid {
                src
              }
            }
            paragraph {
              json
            }
          }
        }
        ... on ContentfulModuleInformative {
          id
          uid
          image {
            fluid {
              src
            }
          }
          tagline
          highlightImage {
            fluid {
              src
            }
          }
          header
          components {
            ... on ContentfulComponentEditorialBlockImage {
              id
              paragraph {
                json
              }
              image {
                fluid {
                  src
                }
              }
              header
            }
            ... on ContentfulComponentEditorialBlock {
              id
              paragraph {
                json
              }
              header
            }
          }
          imagePosition
        }
        ... on ContentfulModuleChecker {
          id
          uid
          backgroundImage {
            file {
              url
            }
          }
          firstTitle
          firstExcerpt {
            firstExcerpt
          }
          secondExcerpt {
            secondExcerpt
          }
          secondTitle
        }
        ... on ContentfulModuleInformativeAdditionalParagraph {
          id
          uid
          image {
            fluid {
              src
            }
          }
          header
          highlightImage {
            fluid {
              src
            }
          }
          imagePosition
          paragraph {
            json
          }
          tagline
          components {
            header
            paragraph {
              json
            }
          }
        }
        ... on ContentfulModuleEvents {
          uid
          components {
            header
            excerpt {
              excerpt
            }
            image {
              file {
                url
              }
            }
            id
          }
        }
        # ... on ContentfulModuleHonoree {
        #   id
        #   uid
        #   firstButton
        #   header
        #   end(formatString: "h:mm A")
        #   start(formatString: "dddd, MMM. DD YYYY - h:mm A")
        #   venueName
        #   address
        #   honoree {
        #     file {
        #       url
        #     }
        #   }
        # }
      }
    }
  }
`;

export default HomePage;
