export default {
  ContentfulComponentHero: "components/hero",
  ContentfulModuleCaption: "components/caption",
  ContentfulModuleInformative: "components/informative",
  ContentfulModuleInformativeAdditionalAddress:
    "components/informative/address",
  ContentfulModuleInformativeAdditionalParagraph:
    "components/informative/paragraph",
  ContentfulModuleChecker: "components/checker",
  ContentfulModuleEvents: "components/activity",
  ContentfulModuleHonoree: "components/honoree"
};
